import React from 'react';
import styled from 'styled-components';
import Heading from '../../utils/common/Heading';
import Tabs from './Tabs';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';
import MobileHeading from '../../utils/common/MobileHeading';

const SectionWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;

  ${media.phone`
    max-width: 576px;
    height: 100%;
    padding: 88px 15px 0 15px;
    margin-bottom: 0;
  ;`}
`;
const TabsSection = () => {
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  return (
    <SectionWrapper>
      {large && <Heading text="С Манго всё быстро и просто" center={false} />}
      {small && (
        <MobileHeading>
          С Манго всё быстро <br />и просто
        </MobileHeading>
      )}
      <Tabs />
    </SectionWrapper>
  );
};

export default TabsSection;
