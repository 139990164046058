import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const StyledMobileHeading = styled.h2`
  font-size: 24px;
  letter-spacing: 0.25px;
  line-height: 34px;
  text-align: center;
  font-weight: 600;
`;

const MobileHeading = ({ children }) => {
  return (
    <Fade top>
      <StyledMobileHeading>{children}</StyledMobileHeading>
    </Fade>
  );
};

MobileHeading.propTypes = {
  children: PropTypes.node.isRequired
};

export default MobileHeading;
