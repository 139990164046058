import React from 'react';
import styled from 'styled-components';
import Heading from '../../utils/common/Heading';
import background from '../../assets/images/bgk.jpg';
import HeroCards from './HeroCards';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';
import MobileHeading from '../../utils/common/MobileHeading';

const SectionWrapper = styled.section`
  margin: 0 auto;
  position: relative;
  text-align: center;
  background-image: url(${background});
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 120px;
  background-position: center;
  background-repeat: no-repeat;

  ${media.phone`
    max-width: 576px;
    height: 100%;
    margin-bottom: 0;
    margin-top: 32px;
    background-size: cover;
    overflow: hidden;
    
  ;`}

  .message-icon {
    position: absolute;
    right: 5%;
    bottom: 0%;
    animation-duration: 0.75s;
    animation-name: fadeIn;
    transition: all 0.3s ease;
    opacity: 0;

    ${media.phone`
      right: 2%;
      bottom: -5%;
      width: 48px;
      height: 48px;
  ;`}
  }
`;

const HeroSection = () => {
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  return (
    <SectionWrapper>
      {large && (
        <Heading
          text="Как защитить квартиру и вещи в ней, когда вы"
          center={false}
        />
      )}
      {small && (
        <MobileHeading>
          Как защитить <br />
          квартиру и вещи <br />в ней, когда вы
        </MobileHeading>
      )}
      <HeroCards />
    </SectionWrapper>
  );
};

export default HeroSection;
