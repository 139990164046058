import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import techIcon from '../../assets/icons/tech.png';
import gainIcon from '../../assets/icons/gain.png';
import supportIcon from '../../assets/icons/support.png';
import media from '../../utils/helpers/media';

const cards = [
  {
    id: 1,
    class: 'first',
    icon: techIcon,
    label: 'Технологии',
    text:
      'Мы доверяем нашим клиентам и используем современные технологии для проверки данных, чтобы исключить мошенничество.'
  },
  {
    id: 2,
    class: 'second',
    icon: supportIcon,
    label: 'Поддержка',
    text:
      'Мы любим наших клиентов и хотим, чтобы у них был самый классный страховой сервис и поддержка в любой жизненной ситуации.'
  },
  {
    id: 3,
    class: 'third',
    icon: gainIcon,
    label: 'Выгода',
    text:
      'Мы хотим, чтобы люди знали, что у каждого есть возможность пользоваться страхованием. Это выгоднее — мы посчитали..'
  }
];

const CardsContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  max-width: 1440px;
  margin: 92px auto 0 auto;

  ${media.phone`
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  ;`}
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 34px;
  font-family: 'Proxima Nova', sans-serif;
  transition: all 0.3s ease;

  ${media.phone`
    margin-right: 0;
    margin-top: 40px;
  ;`}

  .first {
    width: 65px;
    height: 57px;

    ${media.phone`
      width: 64px;
      height: 57px;
  ;`}
  }
  .second {
    width: 74px;
    height: 65px;

    ${media.phone`
    width: 67px;
    height: 60px;
  ;`}
  }
  .third {
    width: 65px;
    height: 70px;
    margin-right: 8px;

    ${media.phone`
    width: 62px;
    height: 67px;
  ;`}
  }

  ${media.phone`
    margin-right: 0;
    animation-duration: 0.75s;
    animation-name: fadeIn;
  ;`}

  h3 {
    font-weight: 600;
    letter-spacing: 0.44px;
    margin: 15px 0;
    font-size: 20px;
    line-height: 28px;

    ${media.phone`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
  ;`}
  }

  p {
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.44px;
    line-height: 24px;
    width: 320px;

    ${media.phone`
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      width: 287px;
  ;`}
  }
`;

const AboutCards = () => {
  return (
    <>
      <CardsContainer>
        {cards.map((card, i) => (
          <Fade delay={i * 200} key={card.id}>
            <Card>
              <img src={card.icon} alt="icon" className={card.class} />
              <h3>{card.label}</h3>
              <p>{card.text}</p>
            </Card>
          </Fade>
        ))}
      </CardsContainer>
    </>
  );
};

export default AboutCards;
