import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Swipeable } from 'react-swipeable';
import Zoom from 'react-reveal/Zoom';
import HeroCard from './HeroCard';
import cardOne from '../LazyLoadImages/HeroOne';
import cardTwo from '../LazyLoadImages/HeroTwo';
import cardThree from '../LazyLoadImages/HeroThree';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';
import HomeAction from './HomeAction';
import MailInfoModal from './MailInfoModal';

const cards = [
  {
    id: 1,
    image: cardOne,
    title: 'Живёте в своей',
    buttonLabel: 'Узнать условия',
    to: '/landing',
    className: 'first'
  },
  {
    id: 2,
    image: cardTwo,
    title: 'Сдaёте',
    buttonLabel: 'Узнать первым',
    to: '/',
    className: 'second'
  },
  {
    id: 3,
    image: cardThree,
    title: 'Снимаете',
    buttonLabel: 'Узнать первым',
    to: '/',
    className: 'third'
  }
];

const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;

  ${media.phone`
    position: relative;
    height: auto;
    padding-bottom: 25px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
      display: none;
    }
  ;`}

  .left {
    position: absolute;
    left: -55%;
    top: 10%;

    @media (max-width: 415px) {
      left: -40%;
    }
    @media (max-width: 376px) {
      left: -45%;
    }
    @media (max-width: 320px) {
      left: -60%;
    }
  }
  .right {
    position: absolute;
    right: -55%;
    top: 10%;

    @media (max-width: 415px) {
      right: -40%;
    }
    @media (max-width: 376px) {
      right: -45%;
    }
    @media (max-width: 320px) {
      right: -60%;
    }
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 25px;
  padding-top: 30px;
  padding-right: 50px;
  scroll-padding: 50%;
  position: relative;
  height: 300px;
  margin-top: 30px;
  transition: all 0.3s ease;
  transform: ${props => {
    if (props.right) return 'translateX(233px)';
    if (props.left) return 'translateX(-233px)';
    return 'translateX(0)';
  }};

  @media (max-width: 320px) {
    transform: ${props => {
      if (props.right) return 'translateX(222px)';
      if (props.left) return 'translateX(-222px)';
      return 'translateX(0)';
    }};
    transition: all 0.3s ease;
  }
  .first {
    position: absolute;
    top: 0;
    left: 18%;
    transition: all 0.5s;
    @media (max-width: 415px) {
      left: 25%;
    }
    @media (max-width: 390px) {
      left: 23%;
    }
    @media (max-width: 358px) {
      left: 21%;
    }
    @media (max-width: 320px) {
      left: 17.5%;
    }
  }
  .second {
    position: absolute;
    top: 0;
    left: -50%;
    transition: all 0.5s;
    @media (max-width: 415px) {
      left: -35%;
    }
    @media (max-width: 405px) {
      left: -35%;
    }
    @media (max-width: 390px) {
      left: -40%;
    }
    @media (max-width: 358px) {
      left: -45%;
    }
    @media (max-width: 320px) {
      left: -51%;
    }
  }
  .third {
    position: absolute;
    top: 0;
    right: -52%;
    transition: all 0.5s;
    @media (max-width: 580px) {
      right: -35%;
    }
    @media (max-width: 405px) {
      right: -35%;
    }
    @media (max-width: 390px) {
      right: -40%;
    }
    @media (max-width: 358px) {
      right: -45%;
    }
    @media (max-width: 320px) {
      right: -51%;
    }
  }
`;

const Info = styled.p`
  margin-top: 56px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #818a97;
  margin-bottom: 22px;

  ${media.phone`
    width: 300px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.44px;
    margin: 55px auto 16px auto;
  ;`}
`;

const Cards = () => {
  const [activeCard, setActiveCard] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');

  useEffect(() => {
    setActiveCard(1);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleLeft = () => {
    setActiveCard(activeCard <= 0 ? 0 : activeCard - 1);
  };
  const handleRight = () => {
    setActiveCard(activeCard > 1 ? 2 : activeCard + 1);
  };

  const config = {
    onSwipedLeft: () => handleLeft(),
    onSwipedRight: () => handleRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  };
  return (
    <>
      {large && (
        <CardsWrapper>
          {cards.map((card, i) => (
            <Zoom delay={(i + 1) * 200} key={card.id}>
              <HeroCard card={card} openModal={i !== 0 ? openModal : null} />
            </Zoom>
          ))}
        </CardsWrapper>
      )}
      {small && (
        <Swipeable {...config}>
          <MobileWrapper right={activeCard === 2} left={activeCard === 0}>
            {cards.map((card, i) => (
              <div className={card.className}>
                <HeroCard
                  card={card}
                  style={card.card}
                  openModal={i !== 0 ? openModal : null}
                />
              </div>
            ))}
          </MobileWrapper>
        </Swipeable>
      )}
      {isModalOpen && <MailInfoModal closeModal={closeModal} />}
      <Info>Оформите полис, подходящий именно для вашей квартиры</Info>
      <HomeAction mobileButton landing />
    </>
  );
};

export default Cards;
