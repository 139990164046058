import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import vars from '../../utils/styles/vars';
import media from '../../utils/helpers/media';
import WithImage from '../LazyLoadImages/WithImage';

const CardContainer = styled.div`
  height: 400px;
  width: 320px;
  transition: all 0.2s ease;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(201, 188, 224, 0.25);
  margin-right: 20px;
  cursor: pointer;
  overflow: hidden;
  display: grid;

  ${media.phone`
    box-shadow: 0px 10px 20px rgba(201, 188, 224, 0.25);
    height: 300px;
    width: 208px;
    justify-content: space-around;
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin: 0;
    display: block;
  ;`}

  &:focus,
  &:hover {
    transform: scale(1.05);
    z-index: 2;

    ${media.phone`
      transform: scale(1);
      z-index: 1;
    ;`}
  }

  &:hover button {
    color: #fff;
    background: #5224d6;

    ${media.phone`
      color: inherit;
      background: inherit;
    ;`}
  }
`;

const CardTitle = styled.h2`
  font-size: 24px;
  letter-spacing: 0.25px;
  font-weight: normal;
  margin-top: 36px;

  ${media.phone`
      font-size: 20px;
  ;`}
`;

const CardButton = styled.button`
  border: 1px solid ${vars.colors.primaryColor};
  border-radius: 8px;
  height: 46px;
  width: 164px;
  font-size: 18px;
  color: ${vars.colors.primaryColor};
  transition: color background 0.2s ease;
  background: #fff;
  cursor: pointer;
  font-family: 'Proxima Nova', sans-serif;
  transform: translate3d(0, 0, 0);
  margin-top: 10px;

  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    color: #fff;
    background: #5224d6;

    &:hover {
      color: #fff;
      background: #5224d6;
    }
  }

  ${media.phone`
    width: 152px;
    height: 48px;
  ;`}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${vars.colors.primaryColor};
  position: relative;

  &:link,
  &:active {
    outline: ${vars.colors.primaryColor};
  }
`;

const Card = ({ card: { image, title, buttonLabel, to }, openModal }) => {
  return (
    <>
      <CardContainer>
        <CardTitle>{title}</CardTitle>
        <WithImage component={image} />
        <StyledLink to={to} onClick={openModal}>
          <CardButton onClick={openModal}>{buttonLabel}</CardButton>
        </StyledLink>
      </CardContainer>
    </>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    image: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  openModal: PropTypes.func.isRequired
};

export default Card;
