import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSection from '../components/Home/HeroSection';
import TabsSection from '../components/Home/TabsSection';
import AboutSection from '../components/Home/AboutSection';
import PaymentsSection from '../components/Home/PaymentsSection';
import SponsorsSection from '../components/Home/SponsorsSection';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Главная" />
      <HeroSection />
      <TabsSection />
      <PaymentsSection />
      <AboutSection />
      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;
