import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import alphaLogo from '../../assets/images/alfa.svg';
import bankrussiaLogo from '../../assets/images/bank-russia.svg';

const SectionWrapper = styled.section`
  background: white;
  height: 289px;
  padding: 76px 0;

  p {
    font-size: 18px;
    margin: 0 auto;
    line-height: 24px;
    text-align: center;
    width: 627px;
    font-family: 'Proxima Nova', sans-serif;
  }
  ${media.phone`
    display: none;
  ;`}
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  img {
    &:first-of-type {
      border-right: 1px solid #d9dce6;
      padding-right: 66px;
    }
    &:last-of-type {
      padding-left: 55px;
      margin-bottom: 5px;
    }
  }
`;
const SponsorsSection = () => {
  return (
    <SectionWrapper>
      <LogoContainer>
        <img src={alphaLogo} alt="logo" />
        <img src={bankrussiaLogo} alt="logo" />
      </LogoContainer>
      <p>
        Наша компания поддерживается Альфа-групп и имеет все необходимые
        документы регулирующие деятельность страховой компании
      </p>
    </SectionWrapper>
  );
};

export default SponsorsSection;
