import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import FooterImage from '../../assets/images/Vector.svg';
import PaymentsPhone from './PaymentsPhone';
import PaymentsInfo from './PaymentsInfo';
import useMedia from '../../hooks/useMedia';
import homePic from '../../assets/images/home.svg';
import MobileHeading from '../../utils/common/MobileHeading';

const SectionWrapper = styled.section`
  background: linear-gradient(
    to top,
    rgba(237, 239, 255, 0.37) 0%,
    #ffffff 100%
  );
  position: relative;
  overflow: hidden;
  width: 100%;
  ${media.phone`
    margin-top: 25px;
  `};

  .footer-img-large {
    position: absolute;
    bottom: -7px;
    width: 120%;
  }

  .footer-img-small {
    position: absolute;
    bottom: -5px;
    z-index: 55;
  }

  .home-pic {
    position: absolute;
    right: 8%;
    bottom: 10px;
    z-index: -4;
  }
`;

const PaymentsContainer = styled.div`
  padding-top: 100px;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 678px;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;

  ${media.phone`
    padding-top: 5px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    overflow: none;
    position: relative;
  `};
`;

const PaymentsSection = () => {
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  return (
    <>
      {small && (
        <MobileHeading>
          Как Манго выплачивает <br />
          деньги
        </MobileHeading>
      )}
      <SectionWrapper>
        <PaymentsContainer>
          <PaymentsPhone />
          <PaymentsInfo />
        </PaymentsContainer>
        {large && (
          <>
            <img src={FooterImage} alt="#" className="footer-img-large" />
            <img src={homePic} alt="#" className="home-pic" />
          </>
        )}
        {small && (
          <img src={FooterImage} alt="#" className="footer-img-small" />
        )}
      </SectionWrapper>
    </>
  );
};

export default PaymentsSection;
