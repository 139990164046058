import React, { useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import fast from '../../assets/images/fast.png';
import simple from '../../assets/images/simple.png';
import alot from '../../assets/images/alot.png';
import vars from '../../utils/styles/vars';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const tabItems = [
  {
    id: 1,
    title: 'Быстро',
    h3_1: `Оформить полис`,
    h3_2: 'и получить от нас деньги',
    h3: 'Оформить полис и получить от нас деньги',
    p1: `Оформление займет не более 2х минут, вы сможете сделать все через онлайн форму прямо сейчас.`,
    p2: `Для получения выплаты достаточно прислать видео с вашего смартфона. Мы быстро все проверим и отправим вам деньги на карту.`,
    image: fast
  },
  {
    id: 2,
    title: 'Просто',
    h3_1: `Понять, что застраховано`,
    h3_2: 'и получить ответ',
    h3: 'Понять, что застраховано и получить ответ',
    p1: `Мы написали правила простым языком без скрытых условий и мелкого серого текста.`,
    p2: `Соня, Валера и Паша очень любят людей и помогать. Спрашивайте их в чате, если не можете в чем-то разобраться.`,
    image: simple
  },
  {
    id: 3,
    title: 'Много',
    h3_1: `Платим клиентам`,
    h3_2: 'и защищаем в квартире',
    h3: 'Платим клиентам и защищаем в квартире',
    p1: `Помимо компенсации ущерба за все вещи в вашей квартире, мы возвращаем до 20% в виде кешбека.`,
    p2: `Мы оценили все страховки на рынке и сделали самые лучшие условия при цене чуть ниже рынка — около 400р/месяц, за 2-х комнатную квартиру недалеко от центра.`,
    image: alot
  }
];

const TabsContainer = styled.div`
  width: 1000px;
  margin: 0 auto;

  ${media.phone`
    width: 100%;
  ;`}
`;

const Tab = styled.button`
  background: ${props => (props.active ? 'rgba(121, 76, 250, 0.08)' : '#fff')};
  border-radius: 8px;
  color: ${vars.colors.primaryColor};
  font-size: 20px;
  border: none;
  width: 312px;
  height: 54px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Gilroy', sans-serif;

  ${media.phone`
    font-size: 14px;
    width: 96px;
    height: 48px;
  ;`}

  &:focus,
  &:active {
    outline: none;
    background: rgba(121, 76, 250, 0.08);
  }

  &:hover {
    background: rgba(121, 76, 250, 0.08);
    color: #5224d6;
  }
`;

const TabsWrapper = styled.div`
  width: 1000px;
  height: 70px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  margin: 0 auto;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.phone`
    width: 100%;
    height: 100%;
  ;`}
`;

const TabInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 30px;
  height: 350px;
  transition: all 0.3s ease;
  letter-spacing: 0.75px;
  animation-duration: 0.75s;
  animation-name: fadeIn;

  .title {
    font-family: 'Gilroy', sans-serif;
    font-size: 24px;
    line-height: 21px;
    font-weight: 500;
  }

  h5 {
    font-size: 18px;
    letter-spacing: 0.25px;
    margin: 5px;
    line-height: 21px;
  }

  img {
    width: 299px;
    height: 219px;
  }

  ${media.phone`
    flex-direction: column;
    padding: 10px;
    height: 100%;
    text-align: center;
    margin-bottom: 50px;

    &:last-of-type{
      margin-bottom: 90px;
    }

    img{
      width: 176px;
      height: 144px;
      margin: 24px 0;
    }
  ;`}
`;

const TabText = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: left;
  flex-direction: column;
  text-align: left;
  flex: 0 55%;
  height: 250px;
  margin-top: 5px;

  ${media.phone`
    text-align: center;
  ;`}

  .h3-1 {
    height: 50px;
  }

  h5 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
    line-height: 28px;

    ${media.phone`
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      font-weight: 550;
  ;`}

    &:first-of-type {
      margin-bottom: -8px;
      ${media.phone`
        margin-bottom: -3px;
  ;`}
    }
  }
  p {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    letter-spacing: 0.75px;
    font-family: 'Proxima Nova', sans-serif;
    width: 500px;

    ${media.phone`
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      line-height: 20px;
  ;`}
  }
`;

const Tabs = () => {
  const [bindIndex, setBindIndex] = useState(0);
  const changeTab = newIndex => {
    setBindIndex(newIndex);
  };
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');

  return (
    <>
      <TabsContainer>
        {large && (
          <>
            <TabsWrapper>
              {tabItems.map((tabItem, index) => (
                <Tab
                  key={tabItem.id}
                  active={bindIndex === index}
                  onClick={() => changeTab(index)}
                >
                  {tabItem.title}
                </Tab>
              ))}
            </TabsWrapper>
            <TabInfo>
              {tabItems.map((tabItem, index) => (
                <React.Fragment key={tabItem.id}>
                  {bindIndex === index && (
                    <Fade>
                      <TabText>
                        <h5>{small ? tabItem.h3 : tabItem.h3_1}</h5>
                        <h5>{large ? tabItem.h3_2 : null}</h5>
                        <p>{tabItem.p1}</p>
                        <p className="h3-1">{tabItem.p2}</p>
                      </TabText>
                      <img src={tabItem.image} alt="#" />
                    </Fade>
                  )}
                </React.Fragment>
              ))}
            </TabInfo>
          </>
        )}
        {small && (
          <TabInfo>
            {tabItems.map(tabItem => (
              <>
                <img src={tabItem.image} alt="item" />
                <h5 className="title">{tabItem.title}:</h5>
                <TabText>
                  <h5>{tabItem.h3_1}</h5>
                  <h5>{tabItem.h3_2}</h5>
                  <p>{tabItem.p1}</p>
                  <p>{tabItem.p2}</p>
                </TabText>
              </>
            ))}
          </TabInfo>
        )}
      </TabsContainer>
    </>
  );
};

export default Tabs;
