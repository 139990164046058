import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Heading from '../../utils/common/Heading';
import background from '../../assets/images/home-about.svg';
import AboutCards from './AboutCards';
import vars from '../../utils/styles/vars';
import media from '../../utils/helpers/media';

const SectionWrapper = styled.section`
  width: 100%;
  padding: 0 150px;
  text-align: center;
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 77px auto 0 auto;

  ${media.phone`
      padding: 0;
      background: white;
      margin: 60px auto 0 auto;
  ;`}

  a {
    text-decoration: none;
    color: ${vars.colors.primaryColor};
    font-size: 18px;
    margin: 40px 0 57px 0;
    display: inline-block;
    font-family: 'Proxima Nova', sans-serif;
    line-height: 24px;
    transition: color 0.3s ease;
    margin-right: 28px;
    font-weight: 400;

    &:hover {
      color: #5224d6;
    }

    ${media.phone`
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;  
      letter-spacing: 0.75px;
      text-transform: uppercase;
      margin-right: 0;
  ;`}
  }
`;

const AboutSection = () => {
  return (
    <SectionWrapper>
      <Heading text="Почему это работает" center />
      <AboutCards />
      <Link to="/about">Подробнее о нас</Link>
    </SectionWrapper>
  );
};

export default AboutSection;
